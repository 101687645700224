import React from "react";
import coin from '../../../Assests/eTSTImg/coin.png';
import tasks from '../../../Assests/eTSTImg/tasks.png';
import { Link } from 'react-router-dom';

const MinerTaskCard = ({ tPayment, tTitle, id }) => {

    return (
          <Link to={`/app.tst-token/minertask/${id}`}>
            <div className="flex items-start text-sm font-[300] mt-4">
          <div className="w-full bg-[#A67C00] bg-opacity-10 py-2 px-4 rounded-2xl flex">
                <button className="flex items-center">
                <img src={coin} width={40} height={40} alt="" />
                </button>
                <button className="flex flex-col items-start gap-1 ml-4">
                <div className="mt-3 text-2xl font-bold flex items-start">
                <img src={tasks} width={20} height={20} />
                <span className="ml-2">{tPayment}</span>
                </div>
                  <span className="text-gray-300">{tTitle}</span>
                </button>
          </div>
          </div>
          </Link>
        )
}

export default MinerTaskCard;