import { React, useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import tcoin from '../../Assests/eTSTImg/etstcoin.png';

  
  const MinerSignUp = () => {
  
  const navigate = useNavigate();
  const [miners, setMiners] = useState([]);
  const [name, setName] = useState("");
  const [uname, setUname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const referrer = "rosy";


  useEffect(() => {
    axios.get(`${server}/miner/all-miners`, {withCredentials: true}).then((res) => {
      setMiners(res.data.miners);
    })
  }, []);

  const refToCredit = miners && miners.find((itami) => itami.username === referrer);
   
    

    const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await axios
      .post(
        `${server}/miner/create-miner-direct`,
        {
          name,
          uname,
          email,
          referrer,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setIsLoading(false);
        refBonusHandler();
        toast.success("Request Approved!")
        navigate("/app.tst-token/login-miner");
        window.location.reload(true); 
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };


  const refBonusHandler = async () => {
    const refBonusToBeCredited = 10000;
    const refToBeCreditedId = refToCredit?._id;
  
    await axios
    
          .put(
            `${server}/miner/ref/bonus/${refToBeCreditedId}`,
            {
              refBonusToBeCredited: refBonusToBeCredited,
            },
            { withCredentials: true }
          )
          .catch((error) => {
            toast.error(error.response.data.message);
          });    
    }

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      <div className="min-h-screen w-full relative flex flex-col justify-center"
      style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}
      >
      <div className="mt-8 sm:mx-auto sm:w-[80%] sm:max-w-md p-6">
        <div className="py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">

            <div>
              <button
                className="group relative w-full h-[40px] flex justify-center mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-teal-900"
              >
            <img
            src={tcoin}
            width='25px'
            alt=""
            className="mr-2"
            /> Become A Miner
              </button>
            </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-[#fff]"
              >
                Full Name:
              </label>
              <div className="mt-1">
                <input
                  type="name"
                  id="name"
                  name="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="uname"
                className="block text-sm font-medium text-[#fff]"
              >
                Username (single word e.g. gracejohn or grace123):
              </label>
              <div className="mt-1">
                <input
                  type="uname"
                  id="uname"
                  name="uname"
                  required
                  value={uname}
                  onChange={(e) => setUname(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-[#fff]"
              >
                Email:
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-[#fff]"
              >
                Password:
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-teal-900"
              >
              Join
              </button>
            </div>
            
            <div className={`${styles.noramlFlex} w-full`}>
              <h4 className="text-[#fff]">Already a miner?</h4>
              <Link to="/app.tst-token/login-miner" className="text-[#fff] font-[500] pl-2">
                Enter Mine
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
    )}
    </>
    
  );
};

export default MinerSignUp;

