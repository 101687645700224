import React from 'react';
import MinerAccount from "../components/Route/Mine/MinerAccount";

const MinerAccountPage = () => {
    return (
        <>
        <MinerAccount />
        </>
    )
}

export default MinerAccountPage;
