import React, {useState, useEffect} from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../../server";
import etstmain from '../../../Assests/eTSTImg/etstcoin.png';
import etstmain2 from '../../../Assests/eTSTImg/etstcoin2.png';
import coin from '../../../Assests/eTSTImg/coin.png';
import turbo from '../../../Assests/eTSTImg/turbo.png';
import battery from '../../../Assests/eTSTImg/battery.png';
import multitap from '../../../Assests/eTSTImg/multitap.png';
import trophy from '../../../Assests/eTSTImg/trophy.png';
import highVoltage from '../../../Assests/eTSTImg/high-voltage.png';
import bear from '../../../Assests/eTSTImg/bear.png';
import rocket from '../../../Assests/eTSTImg/rocket.png';
import tasks from '../../../Assests/eTSTImg/tasks.png';
import stats from '../../../Assests/eTSTImg/stats.png';
import taskson from '../../../Assests/eTSTImg/taskson.png';
import wallet from '../../../Assests/eTSTImg/wallet.png';
import logout from '../../../Assests/eTSTImg/logout.png';
import './mine.css';
import { useSelector } from "react-redux";

import bronze from '../../../Assests/eTSTImg/lbronze.png';
import silver from '../../../Assests/eTSTImg/lsilver.png';
import gold from '../../../Assests/eTSTImg/lgold.png';
import platinum from '../../../Assests/eTSTImg/lplatinum.png';


const MinerAccount = () => {

    const { minertasks } = useSelector((state) => state.minertask); 
    const { rminer } = useSelector((state) => state.miner);
    const [points, setPoints] = useState(1000);
    const [pointsToSave, setPointsToSave] = useState(0);
    //const [clicks, setClicks] = useState([]);
    const [energy, setEnergy] = useState(1000);
    const [countdown, setCountdown] = useState(70);
    const [isClaimTime, setIsClaimTime] = useState(false);
    const navigate = useNavigate(); 

    //const pointsToAdd = 10;
    const energyToReduce = 5;
    //const pAdding = miner?.totalTokenCollected;
    
   
    
    console.log("rminer is"); 
    console.log(rminer); 

    const tShares = rminer?.totalTokenCollected;


    useEffect(() => {
      const timer =
      countdown > 0 && setInterval(() => setCountdown(countdown - 1), 1000);
      return () => clearInterval(timer);
     
      }, [countdown]);

    useEffect(() => {
      if(countdown === 0){
        setTimeout(() => {
          setIsClaimTime(true);
        }, 2000);
      }
    }, [countdown]);

    const handleClicks = () => {
        if(energy > 0 && isClaimTime){
        setEnergy(energy - energyToReduce);
        setPoints(points + energyToReduce);
        setPointsToSave(pointsToSave + energyToReduce);
        return;
      }
    }

    const pointsUpdateHandler = () => {
      toast.success("Coins Claimed!");
      etstUpdateHandler();
      window.location.reload();
    }

   const logoutHandler = () => {
        axios
          .get(`${server}/miner/logout-miner`, { withCredentials: true })
          .then((res) => {
            navigate("/app.tst-token/login-miner");
            window.location.reload(true);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      };

      const newTokenCollected = pointsToSave;

      console.log(newTokenCollected);

      const etstUpdateHandler = async () => {
    
        const id = rminer?._id;
        
        await axios
        
              .put(
                `${server}/miner/update-miner-tokens/${id}`,
                {
                    newTokenCollected: newTokenCollected,
                },
                { withCredentials: true }
              )
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }


      const level2Handler = async () => {
    
        const id = rminer?._id;

        const newLevel = 2;
        const newLLevel = 2;

        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                },
                { withCredentials: true }
              )
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

      const level3Handler = async () => {
    
        const id = rminer?._id;

        const newLevel = 3;
        const newLLevel = 3;
        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                },
                { withCredentials: true }
              )
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }


      const level4Handler = async () => {
    
        const id = rminer?._id;

        const newLevel = 4;
        const newLLevel = 4;
        
        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                },
                { withCredentials: true }
              )
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }


      const level5Handler = async () => {
    
        const id = rminer?._id;

        const newLevel = 5;
        const newLLevel = 5;

        await axios
        
              .put(
                `${server}/miner/update-miner-level/${id}`,
                {
                    newLevel: newLevel,
                    newLLevel: newLLevel,
                },
                { withCredentials: true }
              )
              .catch((error) => {
                toast.error(error.response.data.message);
              });
      }

    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">

          <div className="fixed top-0 left-0 w-full px-4 pt-3 z-10 flex flex-col items-center text-white">
          <div className="w-full flex justify-between gap-2 mt-1">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[400]">
              <div className="w-full bg-[#000] py-4 rounded-2xl flex justify-around">
              <button className="flex flex-col items-center gap-1">
                  <span className="text-[#c89b2a] font-[400]">Miner</span>
                  <span className="text-gray-400">{rminer?.username === undefined ? "-" : rminer?.username}</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
              <button className="flex flex-col items-center gap-1">
              <span className="text-[#c89b2a] font-[400]">eTST</span>
              <span className="text-gray-400">{rminer?.totalTokenCollected === undefined ? "-" : rminer?.totalTokenCollected}</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
                <button className="flex flex-col items-center gap-1">
                <span className="text-[#c89b2a] font-[400]">Level</span>
                <span className="text-gray-400">{rminer?.boostLevel === undefined ? "-" : rminer?.boostLevel}</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-20"></div>
                <Link to="/app.tst-token/miner/league">
                <button className="flex flex-col items-center gap-1">
                <span className="text-[#c89b2a] font-[400]">League</span>
                {rminer?.leagueLevel === undefined ? "-" : (
                  <>
                {rminer?.leagueLevel === 1 ? <img src={trophy} width={15} height={15} alt="" /> : null}
                {rminer?.leagueLevel === 2 ? <img src={bronze} width={15} height={15} alt="" /> : null}
                {rminer?.leagueLevel === 3 ? <img src={silver} width={15} height={15} alt="" /> : null}
                {rminer?.leagueLevel === 4 ? <img src={gold} width={15} height={15} alt="" /> : null}
                {rminer?.leagueLevel === 5 ? <img src={platinum} width={15} height={15} alt="" /> : null}
                  </>
                )}
                </button>
                </Link>
                </div>
            </div>
          </div>
          <div className="mt-3 text-5xl font-bold flex items-center">
            <img src={coin} width={44} height={44} />
            <span className="ml-2">{points.toLocaleString()}</span>
          </div>
          <Link to="/app.tst-token/miner/league">
          <div className="text-base mt-3 flex items-center">
          {rminer?.leagueLevel === undefined ? "-" : (
                  <>
                {rminer?.leagueLevel === 1 ? <img src={trophy} width={25} height={25} alt="" /> : null}
                {rminer?.leagueLevel === 2 ? <img src={bronze} width={25} height={25} alt="" /> : null}
                {rminer?.leagueLevel === 3 ? <img src={silver} width={25} height={25} alt="" /> : null}
                {rminer?.leagueLevel === 4 ? <img src={gold} width={25} height={25} alt="" /> : null}
                {rminer?.leagueLevel === 5 ? <img src={platinum} width={25} height={25} alt="" /> : null}
                  </>
                )}

            {rminer?.leagueLevel === undefined ? "-" : (
                <>
                {rminer?.leagueLevel === 1 ? <span className="ml-1 font-[400]">Starter <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                {rminer?.leagueLevel === 2 ? <span className="ml-1 font-[400]">Bronze <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                {rminer?.leagueLevel === 3 ? <span className="ml-1 font-[400]">Silver <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                {rminer?.leagueLevel === 4 ? <span className="ml-1 font-[400]">Gold <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                {rminer?.leagueLevel === 5 ? <span className="ml-1 font-[400]">Platinum <AiOutlineArrowRight size={18} className="ml-0 mb-1 inline-block" /></span> : null}
                </>
            )}    
            
          </div>
          </Link>
        </div>

        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        <div className="w-full flex justify-between gap-8">
            <div className="w-1/3 flex items-center justify-start max-w-30">
              <div className="flex items-center justify-center">
                <img src={highVoltage} width={44} height={44} alt="High Voltage" />
                <div className="ml-2 text-left">
                  {isClaimTime ? <span className="text-white text-2xl font-bold block">{energy}</span> : <span className="text-white text-large opacity-75 block">charging...</span>}
                  <span className="text-white text-large opacity-75">/ 1000</span>
                </div>
              </div>
            </div>
            {isClaimTime ? (
              <div className="flex-grow flex items-center max-w-5 text-sm">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-3 rounded-2xl flex justify-around">
              <button className="flex flex-col items-center gap-1" onClick={pointsUpdateHandler}>
                <img src={etstmain} width={24} height={24} alt="etst" /> 
                  <span className="text-[#c89b2a]">Claim Now</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <div className="flex flex-col items-center gap-1 cursor-pointer" onClick={logoutHandler}>
                  <img src={logout} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300 font-[300]">Logout</span> 
                </div>
               </div>
            </div>
            ) : (
              <div className="flex-grow flex items-center max-w-5 text-sm">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-3 rounded-2xl flex justify-around">
              <button className="flex flex-col items-center gap-1">
                  <span className="text-[#c89b2a]">Claim in</span> 
                  <span className="text-[22px] font-[600]">{countdown} sec</span> 
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <div className="flex flex-col items-center gap-1 cursor-pointer" onClick={logoutHandler}>
                  <img src={logout} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300 font-[300]">Logout</span> 
                </div>
                </div>
            </div>
            )}
          </div>

          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-[#c89b2a] font-[500]">Earn</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/tasks">
                <button className="flex flex-col items-center gap-1">
                {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-gray-300">Tasks</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/friends">
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/boost">
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-gray-300">Boosts</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/stats">
                <button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-gray-300">Stats</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow flex items-center justify-center">
          <div className="relative mt-4" onClick={handleClicks}>
            <img src={etstmain2} width={220} height={220} alt="etst" />
            </div>
        </div>

       </div>
      </div>
        
    )
};

export default MinerAccount;


/*
<div className="w-full bg-[#f9c035] rounded-full mt-4">
            <div className="bg-gradient-to-r from-[#f3c45a] to-[#fffad0] h-4 rounded-full"></div>
          </div>
*/