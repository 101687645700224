import React, {useState, useEffect} from "react";
import { AiOutlineLogin, AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../server";
import etstmain from '../Assests/eTSTImg/etstcoin.png';
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import trophy from '../Assests/eTSTImg/trophy.png';
import highVoltage from '../Assests/eTSTImg/high-voltage.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import stats from '../Assests/eTSTImg/stats.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import wallet from '../Assests/eTSTImg/wallet.png';
import logout from '../Assests/eTSTImg/logout.png';
import { useSelector } from "react-redux";
import MinerTaskCard from '../components/Route/Mine/MinerTaskCard';


const MinerTasks = () => {

    const { minertasks } = useSelector((state) => state.minertask); 
    //const [undonTasks, setUndonTasks] = useState([]);
    const [miner, setMiner] = useState([]);
    
    
    useEffect(() => {
      axios.get(`${server}/miner/getMiner`, {withCredentials: true}).then((res) => {
        setMiner(res.data.miner);
      })
    }, []);      
    
    
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white pt-6">

          <div className="w-full overflow-y-scroll">

            {minertasks && minertasks.length > 0 ? (
                <>
                {minertasks.map((mina) => (
                    <div key={mina.id}>
                    <MinerTaskCard
                    tPayment={mina.taskReward}
                    tTitle={mina.taskTitle}
                    id={mina._id}
                    />
                    </div>
                ))}</>
            ) : (
        <div className="w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
          <div className="text-base mt-1 flex items-center">
            <h5 className="font-[400]">No Task Found!</h5>
          </div>

          <div className="relative mt-6">
            <img src={tasks} width={150} height={150} alt="etst" />
         </div>
        </div>
            )}

          

        </div>

        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/app.tst-token/miner/earn">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-gray-300">Earn</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <button className="flex flex-col items-center gap-1">
                  {minertasks && minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-[#c89b2a] font-[500]">Tasks</span>
                </button>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/friends">
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/boost">
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-gray-300">Boosts</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/stats">
                <button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-gray-300">Stats</span>
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        </div>
      </div>
        
    )
};


const MinerTasksPage = () => {
    return (
        <>
        <MinerTasks />
        </>
    )
}

export default MinerTasksPage;
