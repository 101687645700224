import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { freelanceCategoriesData } from "../../../static/data";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../server";

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]


const Hero = () => {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()
  
  const { user } = useSelector((state) => state.user);
  const [popUp1Open, setPopUp1Open] = useState(false);
  const [specialization, setSpecialization] = useState("");

  useEffect(() => {
    console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

  useEffect(() => {
    const userHasUpdated = user?.specialization;

    if(user && userHasUpdated === undefined){
      setTimeout(() => {
        setPopUp1Open(true);
      }, 15000)
    }
  })

  const specializationHandler = async () => {

    const userId = user?._id;

    await axios.put(
                `${server}/user/specialization/${userId}`,
                {
                  specialization: specialization,
                    },
                { withCredentials: true }
              )
              .then((res) => {
                  toast.success("Update Successful!");
                  setPopUp1Open(false);
                  window.location.reload();
                  })
               .catch((error) => {
                toast.error(error.response.data.message);
                setPopUp1Open(false);
              });
        }

  return (
    <div className="mt-5 pb-8">
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1706024146/herobag2_c863oe.png)",
      }}
    >
      <div className={`${styles.section} w-[90%] 800px:w-[70%]`}>
        <h1
          className={`text-[40px] leading-[0.9] 800px:text-[80px] text-[#3d3a3a] font-[600]`}
        >
          {t('Heading_h')} <br />  <span className="text-[#c89b2a]">{t('Heading_hb')}</span>
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p1')}
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p2')}
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p3')} 
        </p>
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        Have an urgent job for us? hit the 'Urgent Job' button to get it started! 
        </p>
        <Link to="/contact" className="inline-block">
          <div className={`${styles.buttonRed} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               {t('hb_Urgent_Job')}
               </span>
          </div>
      </Link>

        
        <br />
        <br />
        <p className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
        {t('h_p4')}
        <br />

        </p>

        {user ? (
          <Link to="/tasks" className="inline-block">
          <div className={`${styles.button} mt-5`}>
               <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
               {t('hb_View_Tasks')}
               </span>
          </div>
      </Link>
        ):(
          <Link to="/sign-up" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('hb_Join_Now')}
                 </span>
            </div>
        </Link>
        )}

        {popUp1Open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          <div className={`w-[85%] 800px:w-[50%] bg-white shadow rounded min-h-[40vh] p-3`}>
            <form onSubmit={specializationHandler}>
                    <div>
                    <br />
                    <h4 className="text-[25px] font-[400]">Welcome {user?.name}!</h4>
                    <h4 className="text-[16px]">Kindly update your area of specialization.</h4>
                    <br />

          <div>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={specialization}
            onChange={(e) => setSpecialization(e.target.value)}
          >
            <option value="Select">Select</option>
            {freelanceCategoriesData &&
              freelanceCategoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
       
                     <button
                        className={`${styles.button} !h-[42px] text-white`}
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                    <button
                        className={`${styles.button} !h-[42px] text-white mt-5`}
                        onClick={() => setPopUp1Open(false)}
                       >
                       Cancle
                      </button>
                    </form>
          </div>
          
        </div>
      )}
        
      </div>
     </div>
     </div>
  );
};

export default Hero;

/*
style={{
        backgroundImage:
          "url(https://res.cloudinary.com/totlesoft/image/upload/v1697581589/bagim_pqzu9p.jpg)",
      }}

*/