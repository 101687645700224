import React from 'react'
//import { useSelector } from 'react-redux';
//import { useNavigate } from 'react-router-dom';
import MinerSignUpRef from '../components/Signup/MinerSignUpRef'

const SignupMinerRefPage = () => {
 
  

  return (
    <div>
        <MinerSignUpRef />
    </div>
  )
}

export default SignupMinerRefPage;