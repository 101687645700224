import React, { useEffect, useState} from 'react'
import AdminHeader from '../components/Layout/AdminHeader'
import AdminSideBar from '../components/Admin/Layout/AdminSideBar'
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../redux/actions/user";
import styles from "../styles/styles";
import { server } from "../server";
import { toast } from "react-toastify";
import axios from "axios";

const AdminActionPage = () => {
  return (
    <div>
    <AdminHeader />
    <div className="w-full flex">
      <div className="flex items-start justify-bet ween w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <AdminSideBar active={6} />
        </div>
        <Action />
      </div>
    </div>
  </div>
  )
}

const Action = () => {
  const { users } = useSelector((state) => state.user);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [affPoints, setAffPoints] = useState(0);
  const [action, setAction] = useState("");
  const [role, setRole] = useState("");
  const [staffRole, setStaffRole] = useState("");
   
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);
  
 
 const userData = users && users.find((item) => item._id === id);

 const userId = userData?._id;

  const oldBalance = userData?.commissionBalance;
  const comNewBalance = Number(oldBalance) + Number(affPoints);

  const oldTE = userData?.totalEarning;
  const newTE = Number(oldTE) + Number(affPoints);

 const affiliateCommisionDHandler = async (e) => {
  e.preventDefault();

  await axios

    .put(
      `${server}/user/affiliate-com-payment/${userId}`,
      {
        comNewBalance: comNewBalance,
        newTE: newTE,
        affEarned: affPoints, 
      },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("User credited successfully!");
        navigate('/admin/dashboard');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
};  


const actionUHandler = async (e) => {
  e.preventDefault();

  await axios

    .put(
      `${server}/user/take-action/${userId}`,
      {
        action: action,
      },
      { withCredentials: true }
    )
    .then((res) => {
        toast.success("Action completed!");
        navigate('/admin/dashboard');
        window.location.reload();
        })
     .catch((error) => {
      toast.error(error.response.data.message);
    });
}

const roleHandler = async (e) => {

  e.preventDefault();

  await axios

    .put(
      `${server}/user/promote-demote-user/${userId}`,
      {
        role: role,
       },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Role Updated!");
      navigate('/admin/dashboard');
      window.location.reload();
      })
   .catch((error) => {
    toast.error(error.response.data.message);
  });
}


const staffRoleHandler = async (e) => {

  e.preventDefault();

  await axios

    .put(
      `${server}/user/staffing/${userId}`,
      {
        staffRole: staffRole,
       },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Staffing Completed!");
      navigate('/admin/dashboard');
      window.location.reload();
      })
   .catch((error) => {
    toast.error(error.response.data.message);
  });
}
  

    return (

      <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
      
    >
      <div className={`${styles.section} w-[90%] 800px:w-[90%]`}>
        <br />
        <br />
      <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
        >
          Action Page
        </h1> 
        <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          Warning! Any desicion taken here will affect the targeted user either positively or negatively. You mus ensure to check 
          the targeted user's details properly before hiting the submit button.
        </h2>
        <br />
        <hr />
        <br />

        <h3
          className={`text-[25px] leading-[1.2] 500px:text-[40px] text-[#3d3a3a] font-[600] capitalize`}
        >
          {userData?.name} <span className='text-[15px] text-[red]'> - Targeted User</span>
        </h3>
        <br />

        <div className="flex w-full">
            <div className="relative">
              <img
                src={`${userData?.avatar?.url}`}
                className="w-[100px] h-[100px] rounded-full object-cover border-[3px] border-[#fff] mt-5"
                alt=""
              />
            </div>
          </div>
          <br />

          <h5 className="text-[16px] mb-1">Country: {userData?.country}</h5>
          <h5 className="text-[16px] mb-1">Email: {userData?.email}</h5>
          <h5 className="text-[16px] mb-1">Phone: {userData?.phoneNumber}</h5>
          <h5 className="text-[16px] mb-1">Username: {userData?.username}</h5>
          <h5 className="text-[16px] mb-1">Acct. Status: {userData?.accountStatus}</h5>
          <h5 className="text-[16px] mb-1">Total Ref: {userData?.totalReferrers}</h5>
          <h5 className="text-[16px] mb-1">Rating: {userData?.workerRating}</h5>
          <h5 className="text-[16px] mb-1">C-Jobs: {userData?.completedJobs}</h5>
          <h5 className="text-[16px] mb-1">R-Jobs: {userData?.rejectedJobs}</h5>
          <h5 className="text-[16px] mb-1">Earned: {userData?.totalEarning}</h5>
          <h5 className="text-[16px] mb-1">Joined: {userData?.createdAt}</h5>

                        
        <br />
        <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        AFFILIATE COMMISION DISBURSMENT:
        </h1>
        <br />

        <div className='bg-[#000000ba] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Enter Point(s):</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={affPoints}
                    onChange={(e) => setAffPoints(e.target.value)}
                  />
                </div>
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={affiliateCommisionDHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        SUSPEND/ACTIVATE A USER:
        </h1>
        <br />

        <div className='bg-[#000000ba] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Action</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{userData?.accountStatus}</h5>
                    <div className="mt-1">
                    <select
                    name="action"
                    onChange={(e) => setAction(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="suspended">Suspend</option>
                    <option value="active">Activate</option>
                    </select>
                  </div>
                </div>
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={actionUHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        PROMOTE/DEMOTE USER:
        </h1>
        <br />

        <div className='bg-[#000000ba] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
              <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">New Role</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{userData?.role}</h5>
                    <div className="mt-1">
                    <select
                    name="role"
                    onChange={(e) => setRole(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Admin">Admin</option>
                    <option value="codeVendor">codeVendor</option>
                    <option value="Worker">Worker</option>
                    <option value="user">User</option>
                    </select>
                  </div>
                </div>               
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={roleHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        STAFF CREATION:
        </h1>
        <br />

        <div className='bg-[#000000ba] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
              <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">User email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={userData?.email}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">New Staff Role</label>
                  <h5 className={`${styles.input} !w-[95%] mb-1 800px:mb-0 text-[600] text-[blue]`}>{userData?.role}</h5>
                    <div className="mt-1">
                    <select
                    name="staffRole"
                    onChange={(e) => setStaffRole(e.target.value)}
                    className="w-[200px] h-[35px] border rounded"
                    >
                    <option value="-">-</option>
                    <option value="Director">Director</option>
                    <option value="Manager">Manager</option>
                    <option value="Content Writer">Content Writer</option>
                    <option value="noneStaff">None Staff</option>
                    <option value="Accountant">Accountant</option>
                    <option value="Marketer">Marketer</option>
                    <option value="Developer">Developer</option>
                    <option value="IT">IT</option>
                    <option value="Business Developer">Business Developer</option>
                    </select>
                  </div>
                </div>               
               </div>

               <button className={`${styles.button} mt-5`}
               onClick={staffRoleHandler}
               >
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Submit
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />



      <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[#000000ba]">
        ADD USER TO A PROJECT:
        </h1>
        <br />

        <div className='bg-[#000000ba] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Enter user email:</label>
                  <input
                    type="email"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value=""
                    //onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Enter ProjectId:</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value=""
                    //onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
               </div>

               <button className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] text-[18px]">
                    Add
                 </span>
              </button>
            </div>
        </div>
      </div>
      <br />
      <br />


      <h1
          className={`text-[20px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600]`}
        >
          Admin Disclaimer
      </h1> 
      <h2 className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
          I hereby agreed to any action taken by Totlesoft Management as a result of any damage(s) caused by this desision from me.
        </h2> 
        <br />
        <br />     

        
      </div>
    </div>
  )
}

export default AdminActionPage


/*
<br />
        <div>
        <h1 className="pt-5 text-[20px] font-[Poppins] font-[600] text-[teal]">
        BANK DETAILS:
        </h1>
        <br />

        <div className='bg-[teal] p-5'>
        <div className='bg-[#ffe135] p-6'>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Bank Country:</label>
                  <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}>
                  {userData?.withdrawMethod.bankCountry}
                  </h5>
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Bank Name:</label>
                  <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}>
                  {userData?.withdrawMethod.bankName}
                  </h5>
                  </div>
               </div>

               <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Acct. Holder:</label>
                  <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}>
                  {userData?.withdrawMethod.bankHolderName}
                  </h5>
                  </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 font-[600]">Acct. Number:</label>
                  <h5 className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}>
                  {userData?.withdrawMethod.bankAccountNumber}
                  </h5>
                 </div>
               </div>

               </div>
        </div>
      </div>
      <br />
      <br />

      */