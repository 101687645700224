import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/styles";
import Header from "../../components/Layout/Header";
import SponsorsLogo from "./SponsorsLogo";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
  {
    code: 'fr',
    name: 'Français',
    country_code: 'fr',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
  {
    code: 'hi',
    name: 'Hindi',
    country_code: 'in',
  },
]

function TFoundation() {
  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()


  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
    document.title = t('app_title')
  }, [currentLanguage, t])

   return (
    <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
    <div className="bg-[green] text-[#fff] w-full mt-6 px-6 py-8">
    <p className="text-[25px] font-[600]">{t('help_ukraine_t')}</p>
        <p className="text-[16px]">{t('help_ukraine_b')}</p>
          
          <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('help_now')}
                 </span>
            </div>
        </Link>
        <br />
        </div>

      <br />
      <br />
        <h1
          className={`!text-[25px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize mt-15px`}
        >
          {t('tf_title')}
        </h1>
        <p className="text-[16px] font-[Poppins] text-[green]">{t('tf_p1')}</p>

        <div className="pt-5 text-[16px] font-[Poppins] text-[#000000ba]">
          <p className="font-[400]">
          {t('tf_p2')}
          </p> <br />

        <p>
        {t('tf_p3')}  
        </p>
        <br /><br />

        <div className="sm:block flex items-center justify-center w-[100%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/X4kc358/poor-african-child.jpg"
            alt="African Children Surfering"
          />
        </div>
        <br />
        <br />
        <p>
        {t('tf_p4')}  
        </p>
        <br />
        <p className="font-[600]">{t('tf_p5t')}</p>
        <p>{t('tf_p5')}
          </p>
          
          <Link to="https://wa.me/447418375846" target="_blank" className="inline-block">
            <div className={`${styles.buttonGold} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('tf_Connect1')}
                 </span>
            </div>
        </Link>
        <br />
        <br />
        <p className="font-[600]">{t('tf_p6t')}</p>
        <p>{t('tf_p6')}
        </p>
          <br />
          <br />

          <div className="sm:block flex items-center justify-center w-[100%]" style={{paddingLeft: "15px", paddingRight: "15px"}}>
          <img
            src="https://i.ibb.co/mG5cJsT/http-com-ft-imagepublish-upp-prod-eu-s3-amazonaws.jpg"
            alt="Totlesoft Foundation"
          />
        </div>
        <br />
        <br />

        <p className="font-[600]">{t('tf_p7t')}</p>
        <p>
        {t('tf_p7')}
        </p>

        <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('donate')}
                 </span>
            </div>
        </Link>
        <br />
        <br /> 
       
       

             
       
        <p className="font-[600]">
        {t('tf_p8t')} 
        </p>
        <p>
        {t('tf_p8')}
        </p> 

         <Link to="https://wa.me/447418375846" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('tf_Connect2')}
                 </span>
            </div>
        </Link>
        <br />
        <br />      
        

        
        <p className="font-[500]">{t('s_aplication')}</p>
        <p>
        {t('tf_p9a')}
        <br />
        {t('tf_p9b')} 
        </p>
        <Link to="#" className="inline-block">
            <div className={`${styles.button} mt-5`}>
                 <span className="text-[#fff] font-[Poppins] !text-[16px] flex items-center py-[10px] px-[10px] text-xl/4">
                 {t('apply_now')}
                 </span>
            </div>
        </Link>
        <p className="text-[red] font-[500] mt-3">{t('a_closed')}</p>
        <br />
        <br />
        
        </div>
        <div className="w-full">
        <SponsorsLogo />
        </div>
        
        <br />
        
        <div className="text-[18px] text-[black] mb-3">
          <marquee scrollamount="4">
            {t('visit')}
          </marquee>
        </div><br /><br />
       
      </div>
  )
}


const TFoundationPage = () => {
  return (
    <div>
      <Header />
      <TFoundation />
      </div>
  );
};


export default TFoundationPage 

