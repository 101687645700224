import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import coin from '../Assests/eTSTImg/coin.png';
import turbo from '../Assests/eTSTImg/turbo.png';
import battery from '../Assests/eTSTImg/battery.png';
import multitap from '../Assests/eTSTImg/multitap.png';
import bear from '../Assests/eTSTImg/bear.png';
import rocket from '../Assests/eTSTImg/rocket.png';
import stats from '../Assests/eTSTImg/stats.png';
import tasks from '../Assests/eTSTImg/tasks.png';
import taskson from '../Assests/eTSTImg/taskson.png';
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { isVisible } from "@testing-library/user-event/dist/utils";


const MinerStats = ({ className, ...rest }) => {

    const { minertasks } = useSelector((state) => state.minertask);  
    const { rminer } = useSelector((state) => state.miner);
    const [miners, setMiners] = useState([]);
    const [viewPortEntered, setViewPortEntered] = useState(false);
    
    
   useEffect(() => {
        axios.get(`${server}/miner/all-miners`, {withCredentials: true}).then((res) => {
          setMiners(res.data.miners);
        })
      }, []);

      console.log(rminer);  

    console.log(miners.length); 

    const totalMiners = miners?.length;
    
    
    
    return (
        <div
        className="min-h-screen px-4 flex flex-col items-center text-white font-medium"
        style={{
        background:
         "linear-gradient(#000, #35063E, rgb(132, 82, 129))",
        }}>
          <div className="w-full z-10 min-h-screen flex flex-col items-center text-white">
          <div className="fixed top-0 left-0 w-full px-6 pt-6 z-10 flex flex-col items-center text-white">
          <div className="mt-4 text-4xl font-bold flex items-center">
          <h5 data-number="5000000000">
          <CountUp {...rest} start={viewPortEntered ? null : 0} end={5000000000} duration={5} delay={0}>
                {({ countUpRef }) => {
                  return (
                    <VisibilitySensor
                      active={!viewPortEntered}
                      onChange={() => {
                        if (isVisible) {
                          setViewPortEntered(true);
                        }
                      }}
                      delayedCall
                    >
                      <span className="number" ref={countUpRef} />
                    </VisibilitySensor>
                  );
                }}
              </CountUp>
          </h5>
            </div>
             <div className="text-base mt-1 flex items-center">
            <h5 className="font-[300]">Maximum Shares</h5>
            </div>
          
          <div className="mt-4 text-4xl font-bold flex items-center">
            <h5>200,000,450</h5>
          </div>
          <div className="text-base mt-1 flex items-center">
            <h5 className="font-[300]">Total Shares</h5>
          </div>

          <div className="mt-4 text-4xl font-bold flex items-center">
            <h5>{totalMiners === undefined ? "-" : totalMiners}</h5>
          </div>
          <div className="text-base mt-1 flex items-center">
            <h5 className="font-[300]">Total Miners</h5>
          </div>

          <div className="mt-4 text-4xl font-bold flex items-center">
            <h5>{rminer?.totalTokenCollected === undefined ? "-" : rminer?.totalTokenCollected.toLocaleString()}</h5>
          </div>
          <div className="text-base mt-1 flex items-center">
            <h5 className="font-[300]">My Shares</h5>
          </div>

          <div className="relative mt-5">
            <img src={stats} width={210} height={210} alt="etst" />
            </div>
        </div>

        
        <div className="fixed bottom-0 left-0 w-full px-4 pb-4 z-10">
        
          <div className="w-full flex justify-between gap-2 mt-4">
           <div className="flex-grow flex items-center max-w-90 text-sm font-[300]">
              <div className="w-full bg-[#000] bg-opacity-70 py-4 px-2 rounded-2xl flex justify-around">
              <Link to="/app.tst-token/miner/earn">
              <button className="flex flex-col items-center gap-1">
                  <img src={coin} width={24} height={24} alt="Earn" />
                  <span className="text-gray-300">Earn</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/tasks">
                <button className="flex flex-col items-center gap-1">
                {minertasks.length > 0 ? <img src={taskson} width={24} height={24} alt="Tasks" /> : <img src={tasks} width={24} height={24} alt="Tasks" />}
                  <span className="text-gray-300">Tasks</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/friends">
                <button className="flex flex-col items-center gap-1">
                  <img src={bear} width={24} height={24} alt="Friends" />
                  <span className="text-gray-300">Friends</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <Link to="/app.tst-token/miner/boost">
                <button className="flex flex-col items-center gap-1">
                  <img src={turbo} width={24} height={24} alt="Boosts" />
                  <span className="text-gray-300">Boosts</span>
                </button>
                </Link>
                <div className="h-[48px] w-[2px] bg-[#fddb6d] bg-opacity-30"></div>
                <button className="flex flex-col items-center gap-1">
                  <img src={stats} width={24} height={24} alt="Stats" />
                  <span className="text-[#c89b2a] font-[500]">Stats</span>
                </button>
                </div>
            </div>
          </div>
        </div>

       </div>
      </div>
        
    )
};


const MinerStatsPage = () => {
    return (
        <>
        <MinerStats />
        </>
    )
}

export default MinerStatsPage;
