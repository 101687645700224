import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Spinner from "../Spinner/Spinner";
import tcoin from '../../Assests/eTSTImg/etstcoin.png';

  
  const MinerLogin = () => {
  
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
    

    const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await axios
      .post(
        `${server}/miner/app.tst-token/login-miner`,
        {
          email,
          password,
        },
        { withCredentials: true }
      )
      .then((res) => {
        setIsLoading(false);
        toast.success("Welcome back!")
        navigate("/app.tst-token/miner/earn");
        window.location.reload(true); 
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
      <div className="min-h-screen w-full relative flex flex-col justify-center"
      style={{
        background:
         "linear-gradient(#000, #350101, rgb(158, 148, 3))",
        }}
      >
      <div className="mt-8 sm:mx-auto sm:w-[80%] sm:max-w-md p-6">
        <div className="py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">

            <div>
              <button
                className="group relative w-full h-[40px] flex justify-center mb-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-teal-900"
              >
            <img
            src={tcoin}
            width='25px'
            alt=""
            className="mr-2"
            /> Enter Mine
              </button>
            </div>

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-[#fff]"
              >
                Email:
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-[#fff]"
              >
                Password:
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            <div className={`${styles.noramlFlex} justify-between`}>
              <div className={`${styles.noramlFlex}`}>
                <input
                  type="checkbox"
                  name="remember-me"
                  id="remember-me"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-[#fff]"
                >
                Remember me
                </label>
              </div>
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-[#c89b2a] hover:text-blue-500"
                >
                  Forgot Password?
                </a>
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-teal-900"
              >
              Enter
              </button>
            </div>
            
            <div className={`${styles.noramlFlex} w-full`}>
              <h4 className="text-[#fff]">Not yet a miner?</h4>
              <Link to="/app.tst-token/signup-miner" className="text-[#fff] font-[500] pl-2">
                Join
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
    )}
    </>
    
  );
};

export default MinerLogin;


/*

backgroundSize: "contain",
        backgroundRepeat: "no-repeat",

        */