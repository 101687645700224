import React from 'react'
//import { useSelector } from 'react-redux';
//import { useNavigate } from 'react-router-dom';
import MinerSignUp from '../components/Signup/MinerSignUp'

const SignupMinerPage = () => {
 
  

  return (
    <div>
        <MinerSignUp />
    </div>
  )
}

export default SignupMinerPage;