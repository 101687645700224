import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MinerLogin from "../components/Login/MinerLogin";

const LoginMinerPage = () => {
  //const navigate = useNavigate();
  //const { isMiner } = useSelector((state) => state.miner);

  
  return (
    <div>
        <MinerLogin />
    </div>
  )
}

export default LoginMinerPage;